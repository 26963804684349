var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "UserManagement width-100" },
    [
      _c(
        "div",
        { staticClass: "UserManagement__page" },
        [
          _c("div", { staticClass: "UserManagement__header" }, [
            _c(
              "div",
              {
                staticClass: "row row--align-center row--justify-between mb-21"
              },
              [
                _c("label", { staticClass: "fs-22 fw-med" }, [
                  _vm._v("User Management")
                ]),
                _c("v-select", {
                  staticClass: "UserManagement__select",
                  attrs: {
                    "aria-label": "Select input for client or employee users",
                    "data-cy": "user-management-sort-by",
                    clearable: false,
                    options: _vm.sortBy.options
                  },
                  on: { input: _vm.setUserType },
                  model: {
                    value: _vm.sortBy.value,
                    callback: function($$v) {
                      _vm.$set(_vm.sortBy, "value", $$v)
                    },
                    expression: "sortBy.value"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "column mb-21" },
              [
                _c(
                  "div",
                  { staticClass: "row row--align-end" },
                  [
                    _vm.sortBy.value === "clients"
                      ? [
                          _c("base-input", {
                            staticClass: "UserManagement__query-input mr-10",
                            attrs: {
                              "data-cy": "user-management-name-search",
                              placeholder: "Soft search",
                              type: "text"
                            },
                            on: {
                              keydown: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.search($event)
                              }
                            },
                            model: {
                              value: _vm.searchAPIValues.name,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.searchAPIValues,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "searchAPIValues.name"
                            }
                          }),
                          _c(
                            "base-button",
                            {
                              staticClass: "bg-white fc-blue mr-10",
                              attrs: {
                                "data-cy": "user-management-search-btn"
                              },
                              on: { click: _vm.search }
                            },
                            [
                              _c("i", { staticClass: "fa fa-search-left" }),
                              _vm._v("\n              Search\n            ")
                            ]
                          )
                        ]
                      : _vm.sortBy.value === "debtors"
                      ? [
                          _c(
                            "base-input",
                            {
                              staticClass: "UserManagement__query-input mr-10",
                              attrs: {
                                "data-cy": "user-management-name-search",
                                label: true,
                                placeholder: "Soft search",
                                type: "text"
                              },
                              on: {
                                keydown: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.search($event)
                                }
                              },
                              model: {
                                value: _vm.searchAPIValues.name,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.searchAPIValues,
                                    "name",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "searchAPIValues.name"
                              }
                            },
                            [_vm._v("\n              Name\n            ")]
                          ),
                          _c(
                            "base-input",
                            {
                              staticClass: "UserManagement__query-input mr-10",
                              attrs: {
                                label: true,
                                placeholder: "Strict search",
                                type: "text"
                              },
                              on: {
                                keydown: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.search($event)
                                }
                              },
                              model: {
                                value: _vm.searchAPIValues.mc,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.searchAPIValues,
                                    "mc",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "searchAPIValues.mc"
                              }
                            },
                            [_vm._v("\n              MC\n            ")]
                          ),
                          _c(
                            "base-button",
                            {
                              staticClass: "bg-white fc-blue mr-10",
                              attrs: {
                                "data-cy": "user-management-search-btn"
                              },
                              on: { click: _vm.search }
                            },
                            [
                              _c("i", { staticClass: "fa fa-search-left" }),
                              _vm._v("\n              Search\n            ")
                            ]
                          ),
                          _c(
                            "base-button",
                            {
                              class: [
                                "bg-white fc-blue mr-10",
                                {
                                  "UserManagement__filter-btn--outlined":
                                    _vm.showAdditionalFilters
                                }
                              ],
                              attrs: {
                                "aria-label":
                                  "Toggles the display of additional search fliter options",
                                id: "Testing__FiltersBtn",
                                name: "Filters"
                              },
                              on: {
                                click: function($event) {
                                  _vm.showAdditionalFilters = !_vm.showAdditionalFilters
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fa fa-optionbars" }),
                              _vm._v("\n              Filters\n            ")
                            ]
                          )
                        ]
                      : _vm.sortBy.value === "employees"
                      ? [
                          _c("base-input", {
                            staticClass: "UserManagement__query-input mr-10",
                            attrs: {
                              "data-cy": "user-management-name-search",
                              placeholder: "Soft search",
                              type: "text"
                            },
                            model: {
                              value: _vm.searchAPIValues.name,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.searchAPIValues,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "searchAPIValues.name"
                            }
                          }),
                          _c(
                            "base-button",
                            {
                              staticClass: "bg-white fc-blue mr-10",
                              attrs: {
                                "data-cy": "user-management-search-btn"
                              },
                              on: { click: _vm.search }
                            },
                            [
                              _c("i", { staticClass: "fa fa-search-left" }),
                              _vm._v("\n              Search\n            ")
                            ]
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _c("transition", { attrs: { name: "Transition__fade" } }, [
                  _vm.showAdditionalFilters
                    ? _c(
                        "div",
                        { staticClass: "row row--align-end mt-10" },
                        [
                          _c(
                            "base-input",
                            {
                              staticClass: "UserManagement__query-input mr-10",
                              attrs: {
                                label: true,
                                placeholder: "Soft search",
                                type: "text"
                              },
                              on: {
                                keydown: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.search($event)
                                }
                              },
                              model: {
                                value: _vm.searchAPIValues.phone,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.searchAPIValues,
                                    "phone",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "searchAPIValues.phone"
                              }
                            },
                            [_vm._v("\n              Phone\n            ")]
                          ),
                          _c(
                            "base-input",
                            {
                              staticClass: "UserManagement__query-input mr-10",
                              attrs: {
                                label: true,
                                placeholder: "Strict search",
                                type: "text"
                              },
                              on: {
                                keydown: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.search($event)
                                }
                              },
                              model: {
                                value: _vm.searchAPIValues.email,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.searchAPIValues,
                                    "email",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "searchAPIValues.email"
                              }
                            },
                            [
                              _vm._v(
                                "\n              Invoice Delivery Email\n            "
                              )
                            ]
                          ),
                          _c("base-toggle", {
                            attrs: {
                              "active-option": _vm.activeDeliveryReqsFilter,
                              "aria-label":
                                "Additional filter options (toggle)",
                              toggles: ["all", "online", "originals", "copies"]
                            },
                            on: { toggle: _vm.setDeliveryRequirements }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row row--align-end" },
              [
                _vm._l(_vm.filters, function(filter, index) {
                  return _c(
                    "button",
                    {
                      key: index,
                      class: [
                        "UserManagement__filter clickable fs-15 capitalize",
                        {
                          "fc-middle": _vm.activeTab !== filter,
                          "UserManagement__filter--active fc-dark fw-med":
                            _vm.activeTab === filter
                        }
                      ],
                      attrs: { "data-cy": "user-management-tab-" + filter },
                      on: {
                        click: function($event) {
                          return _vm.selectTab(filter)
                        }
                      }
                    },
                    [_vm._v("\n          " + _vm._s(filter) + "\n        ")]
                  )
                }),
                _vm.sortBy.value === "clients"
                  ? _c(
                      "base-button",
                      {
                        staticClass: "bg-blue fc-white mb-8 ml-auto",
                        on: { click: _vm.newClient }
                      },
                      [
                        _c("i", { staticClass: "fa fa-add-circle" }),
                        _vm._v("\n          New Client\n        ")
                      ]
                    )
                  : _vm.sortBy.value === "debtors"
                  ? _c(
                      "base-button",
                      {
                        staticClass: "bg-blue fc-white mb-8 ml-auto",
                        on: { click: _vm.newDebtor }
                      },
                      [
                        _c("i", { staticClass: "fa fa-add-circle" }),
                        _vm._v("\n          New Debtor\n        ")
                      ]
                    )
                  : _c(
                      "base-button",
                      {
                        staticClass: "bg-blue fc-white mb-8 ml-auto",
                        attrs: { id: "Testing__NewEmployeeButton" },
                        on: { click: _vm.newEmployee }
                      },
                      [
                        _c("i", { staticClass: "fa fa-add-circle" }),
                        _vm._v("\n          New Employee\n        ")
                      ]
                    )
              ],
              2
            )
          ]),
          _c(
            "div",
            {
              staticClass: "UserManagement__head-wrapper row row--align-center"
            },
            [
              _vm.sortBy.value !== "debtors"
                ? [
                    _c(
                      "label",
                      {
                        staticClass:
                          "UserManagement__head-lbl fc-light fs-12 fw-medium uppercase"
                      },
                      [_vm._v("Name")]
                    ),
                    _c(
                      "label",
                      {
                        staticClass:
                          "UserManagement__head-lbl fc-light fs-12 fw-medium uppercase"
                      },
                      [_vm._v("\n          Registered\n        ")]
                    ),
                    _c(
                      "label",
                      {
                        staticClass:
                          "UserManagement__head-lbl fc-light fs-12 fw-medium uppercase"
                      },
                      [_vm._v("\n          Last Login\n        ")]
                    )
                  ]
                : [
                    _c(
                      "label",
                      {
                        staticClass:
                          "\n            UserManagement__head-lbl UserManagement__head-lbl--debtors\n            fc-light fs-12 fw-medium uppercase\n          "
                      },
                      [_vm._v("\n          Created\n        ")]
                    ),
                    _c(
                      "label",
                      {
                        staticClass:
                          "\n            UserManagement__head-lbl UserManagement__head-lbl--debtors\n            fc-light fs-12 fw-medium uppercase\n          "
                      },
                      [_vm._v("\n          Name\n        ")]
                    ),
                    _c(
                      "label",
                      {
                        staticClass:
                          "\n            UserManagement__head-lbl UserManagement__head-lbl--debtors\n            fc-light fs-12 fw-medium uppercase\n          "
                      },
                      [_vm._v("\n          MC\n        ")]
                    ),
                    _c(
                      "label",
                      {
                        staticClass:
                          "\n            UserManagement__head-lbl UserManagement__head-lbl--debtors\n            fc-light fs-12 fw-medium uppercase\n          "
                      },
                      [_vm._v("\n          Location\n        ")]
                    ),
                    _c(
                      "label",
                      {
                        staticClass:
                          "\n            UserManagement__head-lbl UserManagement__head-lbl--debtors\n            fc-light fs-12 fw-medium uppercase\n          "
                      },
                      [_vm._v("\n          Authority Status\n        ")]
                    )
                  ]
            ],
            2
          ),
          _c(
            "transition-group",
            { attrs: { name: "Transition__fade" } },
            _vm._l(_vm.searchResults, function(result, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "UserManagement__table-item",
                  attrs: { "data-cy": "user-management-result-" + index }
                },
                [
                  _c(
                    "div",
                    {
                      class: [
                        "UserManagement__top-container row",
                        {
                          "UserManagement__top-container--debtors":
                            _vm.sortBy.value === "debtors"
                        }
                      ]
                    },
                    [
                      _vm.sortBy.value !== "debtors"
                        ? [
                            _c("div", { staticClass: "column" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("i", {
                                  class: [
                                    "fa fa-oval fa-10 mr-8",
                                    {
                                      "fc-green": result.status === "active",
                                      "fc-red": result.status === "inactive",
                                      "fc-yellow":
                                        result.status !== "active" &&
                                        result.status !== "inactive"
                                    }
                                  ]
                                }),
                                _vm.sortBy.value === "clients"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass:
                                          "UserManagement__client-names-container clickable",
                                        attrs: {
                                          "data-cy":
                                            "user-management-result-name",
                                          href:
                                            "/client/" + result.id + "/activity"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.goToClientProfile(result)
                                          },
                                          keydown: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.goToClientProfile(result)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "fc-dark fs-15 fw-medium",
                                            attrs: {
                                              id:
                                                "Testing__UserManagementResult-" +
                                                index
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(result.name) +
                                                "\n                  "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "label",
                                          { staticClass: "fc-light fs-12" },
                                          [
                                            _vm._v(
                                              _vm._s(result.shortened_name)
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _c(
                                      "label",
                                      {
                                        staticClass: "fc-dark fs-15 fw-medium",
                                        attrs: {
                                          "data-cy":
                                            "user-management-result-name",
                                          id:
                                            "Testing__UserManagementResult-" +
                                            index
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              result.first_name +
                                                " " +
                                                result.last_name
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                              ])
                            ]),
                            _c("div", { staticClass: "column" }, [
                              _c(
                                "time",
                                {
                                  staticClass: "fs-14",
                                  attrs: {
                                    datetime: _vm._f("datetime")(
                                      result.created_at,
                                      "YYYY-MM-DD hh:mm:ssZ"
                                    )
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("datetime")(
                                          result.created_at,
                                          "MMM DD, YYYY"
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "column" }, [
                              result.user
                                ? _c("label", { staticClass: "fs-14" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm._f("datetime")(
                                            result.user.last_login,
                                            "MMM DD, YYYY"
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm.sortBy.value === "clients"
                              ? _c("div", { staticClass: "column" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "fc-mid fs-14 no-wrap underline",
                                      attrs: {
                                        id:
                                          "Testing__UserManagementEdit-" + index
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.showResetModalClient(
                                            result
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Reset Password\n              "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.sortBy.value === "employees"
                              ? _c("div", { staticClass: "column" }, [
                                  _c("div", [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "fc-mid fs-14 no-wrap underline mr-12",
                                        on: {
                                          click: function($event) {
                                            return _vm.showResetModalEmployee(
                                              result
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Reset Password\n                "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        attrs: {
                                          id:
                                            "Testing__UserManagementEdit-" +
                                            index
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openEditEmployee(result)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fa fa-edit fa--none fa-15 fc-light"
                                        })
                                      ]
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ]
                        : [
                            _c(
                              "a",
                              {
                                staticClass: "row clickable",
                                attrs: {
                                  href: "/debtor/" + result.id + "/profile",
                                  "data-cy":
                                    "user-management-debtor-link-" + index
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.goToDebtorProfile(result)
                                  },
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.goToDebtorProfile(result)
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "column" }, [
                                  _c("label", { staticClass: "fs-15" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("datetime")(
                                          result.created_at,
                                          "MM/DD/YY"
                                        )
                                      )
                                    )
                                  ])
                                ]),
                                _c("div", { staticClass: "column" }, [
                                  _c(
                                    "div",
                                    { staticClass: "row row--align-center" },
                                    [
                                      _c("i", {
                                        class: [
                                          "fa fa-oval fa-10 mr-8",
                                          {
                                            "fc-green":
                                              result.status === "active",
                                            "fc-red":
                                              result.status === "inactive",
                                            "fc-yellow":
                                              result.status === "sandbox"
                                          }
                                        ],
                                        attrs: {
                                          id:
                                            "Testing__UserManagementOval-" +
                                            index
                                        }
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "fc-dark fs-15 fw-medium uppercase",
                                          attrs: {
                                            "data-cy":
                                              "user-management-result-name",
                                            id:
                                              "Testing__UserManagementResult-" +
                                              index
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.filterAlgoliaDebtorName(
                                                result.name
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", { staticClass: "column" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "fs-15",
                                      attrs: {
                                        "data-cy": "user-management-result-mc",
                                        id:
                                          "Testing__UserManagementMCResult-" +
                                          index
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            result.broker
                                              ? result.broker.mc
                                              : "N/A"
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", { staticClass: "column" }, [
                                  result.city && result.state
                                    ? _c("label", { staticClass: "fs-15" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              result.city + ", " + result.state
                                            ) +
                                            "\n                "
                                        )
                                      ])
                                    : _c("label", { staticClass: "fs-15" }, [
                                        _vm._v(
                                          "\n                  N/A\n                "
                                        )
                                      ])
                                ]),
                                _c("div", { staticClass: "column" }, [
                                  _c("label", { staticClass: "fs-15" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          result.broker
                                            ? result.broker.authority_status
                                            : "N/A"
                                        ) +
                                        "\n                "
                                    )
                                  ])
                                ])
                              ]
                            )
                          ]
                    ],
                    2
                  )
                ]
              )
            }),
            0
          )
        ],
        1
      ),
      _c("base-pagination", {
        attrs: {
          "item-count": _vm.pagination.resultCount,
          page: _vm.pagination.page,
          "request-limit": _vm.pagination.requestLimit
        },
        on: { paginate: _vm.paginate }
      }),
      _c(
        "transition",
        { attrs: { name: "Transition__opacity-fade" } },
        [
          _vm.resetPassword.modal
            ? _c("base-modal", {
                scopedSlots: _vm._u(
                  [
                    _vm.resetPassword.clientName
                      ? {
                          key: "label",
                          fn: function() {
                            return [
                              _vm._v(
                                "\n        Are you sure you want to reset " +
                                  _vm._s(_vm.resetPassword.clientName) +
                                  "'s password\n      "
                              )
                            ]
                          },
                          proxy: true
                        }
                      : {
                          key: "label",
                          fn: function() {
                            return [
                              _vm._v(
                                "\n        Are you sure you want to reset " +
                                  _vm._s(_vm.resetPassword.employeeName) +
                                  "'s password\n      "
                              )
                            ]
                          },
                          proxy: true
                        },
                    {
                      key: "actions",
                      fn: function() {
                        return [
                          _c(
                            "base-button",
                            {
                              staticClass: "bg-blue fc-white mr-8",
                              on: { click: _vm.resetPasswordAPI }
                            },
                            [
                              _vm._v(
                                "\n          Yes, reset password\n        "
                              )
                            ]
                          ),
                          _c(
                            "base-button",
                            {
                              staticClass: "bg-light fc-white",
                              on: { click: _vm.hideResetModal }
                            },
                            [_vm._v("\n          Cancel\n        ")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }