<template>
  <div class="UserManagement width-100">
    <div class="UserManagement__page">
      <!-- TOP -->
      <div class="UserManagement__header">
        <div class="row row--align-center row--justify-between mb-21">
          <label class="fs-22 fw-med">User Management</label>
          <v-select
            v-model="sortBy.value"
            @input="setUserType"
            aria-label="Select input for client or employee users"
            class="UserManagement__select"
            data-cy="user-management-sort-by"
            :clearable="false"
            :options="sortBy.options"
          />
        </div>

        <!-- Search inputs for each sort -->
        <div class="column mb-21">
          <div class="row row--align-end">
            <template v-if="sortBy.value === 'clients'">
              <base-input
                v-model.trim="searchAPIValues.name"
                @keydown.enter="search"
                class="UserManagement__query-input mr-10"
                data-cy="user-management-name-search"
                :placeholder="'Soft search'"
                :type="'text'"
              />
              <base-button
                @click="search"
                class="bg-white fc-blue mr-10"
                data-cy="user-management-search-btn"
              >
                <i class="fa fa-search-left" />
                Search
              </base-button>
            </template>
            <template v-else-if="sortBy.value === 'debtors'">
              <base-input
                v-model.trim="searchAPIValues.name"
                @keydown.enter="search"
                class="UserManagement__query-input mr-10"
                data-cy="user-management-name-search"
                :label="true"
                :placeholder="'Soft search'"
                :type="'text'"
              >
                Name
              </base-input>
              <base-input
                v-model.trim="searchAPIValues.mc"
                @keydown.enter="search"
                class="UserManagement__query-input mr-10"
                :label="true"
                :placeholder="'Strict search'"
                :type="'text'"
              >
                MC
              </base-input>
              <base-button
                @click="search"
                class="bg-white fc-blue mr-10"
                data-cy="user-management-search-btn"
              >
                <i class="fa fa-search-left" />
                Search
              </base-button>
              <base-button
                @click="showAdditionalFilters = !showAdditionalFilters"
                aria-label="Toggles the display of additional search fliter options"
                :class="[
                  'bg-white fc-blue mr-10',
                  { 'UserManagement__filter-btn--outlined': showAdditionalFilters }
                ]"
                id="Testing__FiltersBtn"
                name="Filters"
              >
                <i class="fa fa-optionbars" />
                Filters
              </base-button>
            </template>
            <template v-else-if="sortBy.value === 'employees'">
              <base-input
                v-model.trim="searchAPIValues.name"
                class="UserManagement__query-input mr-10"
                data-cy="user-management-name-search"
                :placeholder="'Soft search'"
                :type="'text'"
              />
              <base-button
                @click="search"
                class="bg-white fc-blue mr-10"
                data-cy="user-management-search-btn"
              >
                <i class="fa fa-search-left" />
                Search
              </base-button>
            </template>
          </div>
          <transition name="Transition__fade">
            <div
              v-if="showAdditionalFilters"
              class="row row--align-end mt-10"
            >
              <base-input
                v-model.trim="searchAPIValues.phone"
                @keydown.enter="search"
                class="UserManagement__query-input mr-10"
                :label="true"
                :placeholder="'Soft search'"
                :type="'text'"
              >
                Phone
              </base-input>
              <base-input
                v-model.trim="searchAPIValues.email"
                @keydown.enter="search"
                class="UserManagement__query-input mr-10"
                :label="true"
                :placeholder="'Strict search'"
                :type="'text'"
              >
                Invoice Delivery Email
              </base-input>
              <!-- Delivery Requirements -->
              <base-toggle
                @toggle="setDeliveryRequirements"
                :active-option="activeDeliveryReqsFilter"
                aria-label="Additional filter options (toggle)"
                :toggles="['all', 'online', 'originals', 'copies']"
              />
            </div>
          </transition>
        </div>

        <!-- Tabs + Create "X" Button -->
        <div class="row row--align-end">
          <button
            v-for="(filter, index) in filters"
            :key="index"
            @click="selectTab(filter)"
            :class="['UserManagement__filter clickable fs-15 capitalize', {
              'fc-middle': activeTab !== filter,
              'UserManagement__filter--active fc-dark fw-med': activeTab === filter
            }]"
            :data-cy="`user-management-tab-${filter}`"
          >
            {{ filter }}
          </button>

          <base-button
            v-if="sortBy.value === 'clients'"
            @click="newClient"
            class="bg-blue fc-white mb-8 ml-auto"
          >
            <i class="fa fa-add-circle" />
            New Client
          </base-button>
          <base-button
            v-else-if="sortBy.value === 'debtors'"
            @click="newDebtor"
            class="bg-blue fc-white mb-8 ml-auto"
          >
            <i class="fa fa-add-circle" />
            New Debtor
          </base-button>
          <base-button
            v-else
            @click="newEmployee"
            class="bg-blue fc-white mb-8 ml-auto"
            id="Testing__NewEmployeeButton"
          >
            <i class="fa fa-add-circle" />
            New Employee
          </base-button>
        </div>
      </div>

      <!-- TABLE HEADER-->
      <div class="UserManagement__head-wrapper row row--align-center">
        <template v-if="sortBy.value !== 'debtors'">
          <label class="UserManagement__head-lbl fc-light fs-12 fw-medium uppercase">Name</label>
          <label class="UserManagement__head-lbl fc-light fs-12 fw-medium uppercase">
            Registered
          </label>
          <label class="UserManagement__head-lbl fc-light fs-12 fw-medium uppercase">
            Last Login
          </label>
        </template>

        <template v-else>
          <label
            class="
              UserManagement__head-lbl UserManagement__head-lbl--debtors
              fc-light fs-12 fw-medium uppercase
            "
          >
            Created
          </label>
          <label
            class="
              UserManagement__head-lbl UserManagement__head-lbl--debtors
              fc-light fs-12 fw-medium uppercase
            "
          >
            Name
          </label>
          <label
            class="
              UserManagement__head-lbl UserManagement__head-lbl--debtors
              fc-light fs-12 fw-medium uppercase
            "
          >
            MC
          </label>
          <label
            class="
              UserManagement__head-lbl UserManagement__head-lbl--debtors
              fc-light fs-12 fw-medium uppercase
            "
          >
            Location
          </label>
          <label
            class="
              UserManagement__head-lbl UserManagement__head-lbl--debtors
              fc-light fs-12 fw-medium uppercase
            "
          >
            Authority Status
          </label>
        </template>
      </div>

      <!-- TABLE DATA -->
      <transition-group name="Transition__fade">
        <div
          v-for="(result, index) of searchResults"
          :key="index"
          class="UserManagement__table-item"
          :data-cy="`user-management-result-${index}`"
        >
          <div
            :class="[
              'UserManagement__top-container row', {
                'UserManagement__top-container--debtors': sortBy.value === 'debtors'
              }
            ]"
          >
            <!-- CLIENT/EMPLOYEES -->
            <template v-if="sortBy.value !== 'debtors'">
              <div class="column">
                <div class="row">
                  <i
                    :class="['fa fa-oval fa-10 mr-8', {
                      'fc-green': result.status === 'active',
                      'fc-red': result.status === 'inactive',
                      'fc-yellow': result.status !== 'active' && result.status !== 'inactive'
                    }]"
                  />
                  <!-- CLIENT NAME -->
                  <a
                    v-if="sortBy.value === 'clients'"
                    @click="goToClientProfile(result)"
                    @keydown.enter="goToClientProfile(result)"
                    class="UserManagement__client-names-container clickable"
                    data-cy="user-management-result-name"
                    :href="`/client/${result.id}/activity`"
                  >
                    <!-- Client legal and shortened name should remain as input by underwriter/client (unaltered) -->
                    <label
                      :id="`Testing__UserManagementResult-${index}`"
                      class="fc-dark fs-15 fw-medium"
                    >
                      {{ result.name }}
                    </label>
                    <label class="fc-light fs-12">{{ result.shortened_name }}</label>
                  </a>
                  <!-- EMPLOYEE NAME -->
                  <label
                    v-else
                    class="fc-dark fs-15 fw-medium"
                    data-cy="user-management-result-name"
                    :id="`Testing__UserManagementResult-${index}`"
                  >
                    {{ `${result.first_name} ${result.last_name}` }}
                  </label>
                </div>
              </div>
              <div class="column">
                <time
                  class="fs-14"
                  :datetime="result.created_at | datetime('YYYY-MM-DD hh:mm:ssZ')"
                >
                  {{ result.created_at | datetime('MMM DD, YYYY') }}
                </time>
              </div>
              <div class="column">
                <label
                  v-if="result.user"
                  class="fs-14"
                >
                  {{ result.user.last_login | datetime('MMM DD, YYYY') }}
                </label>
              </div>
              <div
                v-if="sortBy.value === 'clients'"
                class="column"
              >
                <button
                  @click="showResetModalClient(result)"
                  class="fc-mid fs-14 no-wrap underline"
                  :id="`Testing__UserManagementEdit-${index}`"
                >
                  Reset Password
                </button>
              </div>
              <div
                v-if="sortBy.value === 'employees'"
                class="column"
              >
                <div>
                  <button
                    @click="showResetModalEmployee(result)"
                    class="fc-mid fs-14 no-wrap underline mr-12"
                  >
                    Reset Password
                  </button>
                  <button
                    @click="openEditEmployee(result)"
                    :id="`Testing__UserManagementEdit-${index}`"
                  >
                    <i class="fa fa-edit fa--none fa-15 fc-light" />
                  </button>
                </div>
              </div>
            </template>

            <!-- DEBTOR results -->
            <template v-else>
              <a
                @click="goToDebtorProfile(result)"
                @keydown.enter="goToDebtorProfile(result)"
                class="row clickable"
                :href="`/debtor/${result.id}/profile`"
                :data-cy="`user-management-debtor-link-${index}`"
              >
                <!-- CREATED -->
                <div class="column">
                  <label class="fs-15">{{ result.created_at | datetime('MM/DD/YY') }}</label>
                </div>
                <!-- NAME -->
                <div class="column">
                  <div class="row row--align-center">
                    <i
                      :class="['fa fa-oval fa-10 mr-8', {
                        'fc-green': result.status === 'active',
                        'fc-red': result.status === 'inactive',
                        'fc-yellow': result.status === 'sandbox',
                      }]"
                      :id="`Testing__UserManagementOval-${index}`"
                    />
                    <label
                      class="fc-dark fs-15 fw-medium uppercase"
                      data-cy="user-management-result-name"
                      :id="`Testing__UserManagementResult-${index}`"
                    >{{ filterAlgoliaDebtorName(result.name) }}</label>
                  </div>
                </div>
                <!-- MC -->
                <div class="column">
                  <label
                    class="fs-15"
                    data-cy="user-management-result-mc"
                    :id="`Testing__UserManagementMCResult-${index}`"
                  >
                    {{ result.broker ? result.broker.mc : 'N/A' }}
                  </label>
                </div>
                <!-- LOCATION -->
                <div class="column">
                  <!-- Debtors uploaded may not have a city and state value -->
                  <label
                    v-if="result.city && result.state"
                    class="fs-15"
                  >
                    {{ `${result.city}, ${result.state}` }}
                  </label>
                  <label
                    v-else
                    class="fs-15"
                  >
                    N/A
                  </label>
                </div>
                <!-- AUTHORITY STATUS -->
                <div class="column">
                  <label class="fs-15">
                    {{ result.broker ? result.broker.authority_status : 'N/A' }}
                  </label>
                </div>
              </a>
            </template>
          </div>
        </div>
      </transition-group>
    </div>

    <base-pagination
      @paginate="paginate"
      :item-count="pagination.resultCount"
      :page="pagination.page"
      :request-limit="pagination.requestLimit"
    />

    <transition name="Transition__opacity-fade">
      <base-modal v-if="resetPassword.modal">
        <template
          v-if="resetPassword.clientName"
          v-slot:label
        >
          Are you sure you want to reset {{ resetPassword.clientName }}'s password
        </template>
        <template
          v-else
          v-slot:label
        >
          Are you sure you want to reset {{ resetPassword.employeeName }}'s password
        </template>
        <template v-slot:actions>
          <base-button
            @click="resetPasswordAPI"
            class="bg-blue fc-white mr-8"
          >
            Yes, reset password
          </base-button>
          <base-button
            @click="hideResetModal"
            class="bg-light fc-white"
          >
            Cancel
          </base-button>
        </template>
      </base-modal>
    </transition>
  </div>
</template>

<script>
// Packages
import DOMPurify from 'dompurify'
// Helpers
import algoliaSearch from '../utils/algoliaSearch'
import {
  Debtor,
  Employee,
  Client
} from '../utils/api'
import {
  buildAlgoliaFilterString,
  readQueryStringToObject,
  sanitizeAlgoliaNames,
  stringifyObjectToQueryString,
} from '../utils/helpers'
// Components
import BaseButton from '../components/base-button.vue'
import BaseInput from '../components/base-input.vue'
import BaseModal from '../components/base-modal.vue'
import BasePagination from '../components/base-pagination.vue'
import BaseToggle from '../components/base-toggle.vue'

export default {
  name: 'UserManagement',

  components: {
    BaseButton,
    BaseInput,
    BaseModal,
    BasePagination,
    BaseToggle,
  },

  async created () {
    if (this.$route.params.newEmployee || this.$route.params.updatedEmployee) this.sortBy.value = 'employees'
    // Do not allow non-master users to see "employee" sort by option
    if (JSON.parse(localStorage.getItem('user')).employee.is_master) {
      this.sortBy.options.push('employees')
    }

    this.compareDataToQuery()
    await this.search()
  },

  data () {
    return {
      activeDeliveryReqsFilter: 'all',
      activeTab: 'all',
      // Filters for Clients and Employees
      filters: [
        'all',
        'active',
        'inactive'
      ],
      pagination: {
        page: 1,
        requestLimit: 10,
        resultCount: 0
      },
      resetPassword: {
        clientId: false,
        clientName: false,
        employeeId: null,
        employeeName: null,
        modal: false
      },
      searchAPIValues: {
        email: '',
        mc: '',
        name: '',
        phone: '',
        require_copies: null,
        require_online_submit: null,
        require_originals: null,
      },
      searchResults: [],
      showAdditionalFilters: false,
      sortBy: {
        value: 'clients',
        options: [
          'clients',
          'debtors',
        ]
      }
    }
  },

  computed: {
    clientStatus () {
      if (this.activeTab === 'active') return 'status:active'
      if (this.activeTab === 'inactive') return 'NOT status:active'
      return ''
    },

    debtorStatus () {
      if (this.activeTab === 'all') return ['sandbox', 'active', 'inactive']
      if (this.activeTab === 'active') return ['active']
      if (this.activeTab === 'sandbox') return ['sandbox']
      if (this.activeTab === 'inactive') return ['inactive']
      return null
    },

    offset () {
      return (this.pagination.page - 1) * this.pagination.requestLimit
    },
  },

  methods: {
    compareDataToQuery () {
      // Query string? Compare variables with component data; if different, update data
      const queryString = window.location.search

      if (queryString) {
        const cleanedQueryString = queryString.substring(1) // Removed leading '?'
        const params = readQueryStringToObject(cleanedQueryString)
        console.log(params)

        if (params.email) this.$set(this.searchAPIValues, 'email', params.email)
        if (params.mc) this.$set(this.searchAPIValues, 'mc', params.mc)
        if (params.name) this.$set(this.searchAPIValues, 'name', params.name)
        // We need to divide the offset by the requestLimit and add 1 since the first page should be 1 not 0
        if (params.offset) this.$set(this.pagination, 'page', Number(params.offset) / this.pagination.requestLimit + 1)
        if (params.phone) this.$set(this.searchAPIValues, 'phone', params.phone)
        if (params.require_copies) {
          this.$set(this.searchAPIValues, 'require_copies', true)
          this.activeDeliveryReqsFilter = 'copies'
        }
        if (params.require_online_submit) {
          this.$set(this.searchAPIValues, 'require_online_submit', true)
          this.activeDeliveryReqsFilter = 'online'
        }
        if (params.require_originals) {
          this.$set(this.searchAPIValues, 'require_originals', true)
          this.activeDeliveryReqsFilter = 'originals'
        }
        if (params.sort) this.$set(this.sortBy, 'value', params.sort)
        if (params.status) this.activeTab = params.status

        // Make sure the correct tabs are displayed
        this.setFilterTabs()
      }
    },

    // Remove <em></em> from debtor name
    // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1694
    filterAlgoliaDebtorName (name) {
      return sanitizeAlgoliaNames(name)
    },

    getAlgoliaFilterString (status) {
      return buildAlgoliaFilterString({
        or___status: status,
        require_copies: this.searchAPIValues.require_copies,
        require_originals: this.searchAPIValues.require_originals,
        require_online_submit: this.searchAPIValues.require_online_submit
      })
    },

    goToClientProfile (client) {
      this.$router.push({
        name: 'client',
        params: {
          id: client.id,
        },
      })
    },

    goToDebtorProfile (user) {
      this.$router.push({
        name: 'debtor',
        params: {
          id: user.id,
        },
      })
    },

    hideResetModal () {
      this.$set(this.resetPassword, 'clientName', '')
      this.$set(this.resetPassword, 'modal', false)
    },

    newClient () {
      this.$router.push({ name: 'create-client' })
    },

    newDebtor () {
      this.$router.push({ name: 'create-debtor' })
    },

    newEmployee () {
      this.$router.push({ name: 'create-employee' })
    },

    openEditEmployee (employee) {
      this.$router.push({
        name: 'edit-employee',
        params: {
          employee
        }
      })
    },

    async paginate (page) {
      this.$set(this.pagination, 'page', page)
      // Ensure the user is brought back to the top of the page
      window.scrollTo(0, 0)

      await this.search({}, page)
    },

    async resetPasswordAPI () {
      this.progressStart()

      try {
        // await
        const name = this.resetPassword.clientId
          ? this.resetPassword.clientName
          : this.resetPassword.employeeName

        let email
        if (this.resetPassword.clientId) {
          email = (await Client.resetPassword({ id: this.resetPassword.clientId })).data.email
        }
        if (this.resetPassword.employeeId) {
          email = (await Employee.resetPassword({ id: this.resetPassword.employeeId })).data.email
        }

        this.requestSuccess({ message: `${name}'s password reset. An email has been sent to ${email}!` })
      }
      catch (error) {
        this.captureSentryEvent(
          'User Management "Reset Password"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue reseting the client\'s password'
        })
      }
      this.$set(this.resetPassword, 'clientName', false)
      this.$set(this.resetPassword, 'clientId', false)
      this.$set(this.resetPassword, 'employeeName', null)
      this.$set(this.resetPassword, 'employeeId', null)
      this.$set(this.resetPassword, 'modal', false)
    },

    async setDeliveryRequirements (filter) {
      // Reset
      const defaultValues = this.$options.data().searchAPIValues
      this.$set(this.searchAPIValues, 'require_copies', defaultValues.require_copies)
      this.$set(this.searchAPIValues, 'require_online_submit', defaultValues.require_copies)
      this.$set(this.searchAPIValues, 'require_originals', defaultValues.require_copies)

      if (filter === 'copies') this.$set(this.searchAPIValues, 'require_copies', true)
      if (filter === 'online') this.$set(this.searchAPIValues, 'require_online_submit', true)
      if (filter === 'originals') this.$set(this.searchAPIValues, 'require_originals', true)

      this.activeDeliveryReqsFilter = filter
    },

    showResetModalClient (result) {
      this.$set(this.resetPassword, 'clientId', result.id)
      this.$set(this.resetPassword, 'clientName', result.name)
      this.$set(this.resetPassword, 'modal', true)
    },

    showResetModalEmployee (result) {
      this.$set(this.resetPassword, 'employeeId', result.id)
      this.$set(this.resetPassword, 'employeeName', result.first_name)
      this.$set(this.resetPassword, 'modal', true)
    },

    async search (event, page) {
      // Event is always unused (it's the default param from having search
      // as a callback in the template
      // If page doesn't exists (only is passed when paginating), reset pagination
      if (!page) {
        this.$set(this.pagination, 'page', 1)
        this.$set(this.pagination, 'resultCount', 0)
      }
      if (this.sortBy.value === 'clients') await this.searchClients()
      else if (this.sortBy.value === 'debtors') await this.searchDebtors()
      else await this.searchEmployees()
    },

    async searchClients () {
      try {
        const clients = (await algoliaSearch.clientSearch(
          DOMPurify.sanitize(this.searchAPIValues.name.trim()),
          this.pagination.requestLimit,
          {
            filters: this.clientStatus ? this.clientStatus : undefined,
            page: this.pagination.page - 1,
          },
        )).data
        const query = stringifyObjectToQueryString({
          name: this.searchAPIValues.name,
          // Offset is our backend property for pagination, but to keep created () simple, we'll
          // use offset everywhere and just ensure it's used to set the pagination info in data()
          offset: this.offset,
          sort: 'clients',
          // DO NOT REMOVE STATUS: Must be in query to ensure users can revisit the same user-management
          // page and settings on reload/backwards navigation
          status: this.activeTab.toLowerCase()
        })

        // Update URL with Query
        window.history.replaceState({}, '', `?${query}`)

        this.$set(this.pagination, 'resultCount', clients.nbHits)
        this.searchResults = clients.hits
      }
      catch (error) {
        this.captureSentryEvent(
          'User Management "Client Search"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.searchResults = []
        this.requestFailure({ message: 'There was an issue finding matching clients' })
      }
    },

    async searchDebtors () {
      try {
        const debtors = await this.searchBackendDebtors()
        const query = stringifyObjectToQueryString({
          email: this.searchAPIValues.email,
          mc: this.searchAPIValues.mc,
          name: this.searchAPIValues.name,
          // Offset is our backend property for pagination, but to keep created () simple, we'll
          // use offset everywhere and just ensure it's used to set the pagination info in data()
          offset: this.offset,
          phone: this.searchAPIValues.phone,
          require_copies: this.searchAPIValues.require_copies,
          require_originals: this.searchAPIValues.require_originals,
          require_online_submit: this.searchAPIValues.require_online_submit,
          sort: 'debtors',
          // DO NOT REMOVE STATUS: Must be in query to ensure users can revisit the same user-management
          // page and settings on reload/backwards navigation
          status: this.activeTab.toLowerCase()
        })

        // Update URL with Query
        window.history.replaceState({}, '', `?${query}`)

        this.$set(this.pagination, 'resultCount', debtors.nbHits || debtors.count)
        this.searchResults = debtors.hits || debtors.rows
      }
      catch (error) {
        this.captureSentryEvent(
          'User Management "Debtor Search"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.searchResults = []
        this.requestFailure({ message: 'There was an issue finding matching debtors' })
      }
    },

    async searchBackendDebtors () {
      // Sandbox debtors aren't stored in Algolia
      const params = {
        // If '', must pass null for 'in_array___'
        in_array___invoice_delivery_emails: this.searchAPIValues.email === '' ? null : this.searchAPIValues.email,
        // Must be an exact match (according to DJ)
        // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1857
        mc: this.searchAPIValues.mc,
        like___name: this.searchAPIValues.name ? this.searchAPIValues.name : undefined,
        offset: (this.pagination.page - 1) * this.pagination.requestLimit,
        like___phone: this.searchAPIValues.phone ? this.searchAPIValues.phone : undefined,
        require_copies: this.searchAPIValues.require_copies,
        require_originals: this.searchAPIValues.require_originals,
        require_online_submit: this.searchAPIValues.require_online_submit,
        status: this.activeTab === 'all' ? undefined : this.activeTab,
        sort_by: 'created_at',
        sort_direction: 'desc'
      }
      return (await Debtor.queryList(params, this.pagination.requestLimit)).data
    },

    async searchEmployees () {
      try {
        // If you're on debtor and the activeTab is 'sandbox,' you need to reset the value for activeTab
        if (!['active', 'inactive', 'all'].includes(this.activeTab)) this.activeTab = 'all'

        const params = {
          offset: this.offset,
          name_search: this.searchAPIValues.name ? this.searchAPIValues.name : undefined,
          status: this.activeTab === 'all' ? null : this.activeTab.toLowerCase()
        }

        const employees = (await Employee.getEmployees(params, this.pagination.requestLimit)).data
        const query = stringifyObjectToQueryString(Object.assign({}, params, {
          sort: 'employees',
          // DO NOT REMOVE STATUS: Must be in query to ensure users can revisit the same user-management
          // page and settings on reload/backwards navigation
          status: this.activeTab.toLowerCase()
        }))

        // Update URL with Query
        window.history.replaceState({}, '', `?${query}`)

        this.$set(this.pagination, 'resultCount', employees.count)
        this.searchResults = employees.rows
      }
      catch (error) {
        this.captureSentryEvent(
          'User Management "Employee Search"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.searchResults = this.$options.data().searchResults
        this.requestFailure({
          message: 'There was an issue finding matching employees'
        })
      }
    },

    async selectTab (filter) {
      this.activeTab = filter
      await this.search()
    },

    setFilterTabs () {
      // This code is needed at the end of the created () but also used in this.setUserType,
      // however, setUserType has code we don't want in created ()

      // Dynamically set the tab texts
      if (this.sortBy.value === 'debtors') {
        this.filters = [
          'all',
          'active',
          'sandbox',
          'inactive',
        ]
      } else {
        this.filters = [
          'all',
          'active',
          'inactive',
        ]
      }
    },

    async setUserType() {
      const defaultValues = this.$options.data()
      this.setFilterTabs()
      // Update all our search values to their default values
      this.$set(this.pagination, 'resultCount', 0)
      this.$set(this.pagination, 'page', 1)
      this.activeTab = defaultValues.activeTab
      this.searchAPIValues = defaultValues.searchAPIValues
      this.searchResults = defaultValues.searchResults
      this.showAdditionalFilters = defaultValues.showAdditionalFilters
      // Search now that everything is set or reset for a new type of user
      await this.search()
    }
  }
}
</script>

<style lang="sass">
.UserManagement
  height: 100%

  // Using a specific height to keep the oval centered in debtor/client/employee legal name
  // without using row--align-center on the items
  .fa-oval
    +flex($align: center)
    height: rem(23px)

  &__client-names-container
    +flex(column)

  &__filter
    margin-bottom: rem(2px)
    margin-right: rem(33px)
    padding: 0 0 rem(12px)
    white-space: nowrap

    &--active
      border-bottom: rem(2px) solid $branding
      margin-bottom: 0

  &__filter-btn

    &--outlined
      box-shadow: none !important
      border: rem(1px) solid $branding !important

      &:focus
        box-shadow: none

  &__head-lbl
    &:nth-child(1) // Name
      padding-right: 1rem
      width: 30%
    &:nth-child(2) // Registered
      padding-right: 1rem
      width: 30%
    &:nth-child(3) // Last login
      white-space: nowrap
      width: 20%
    &:last-child // buttons
      white-space: nowrap
      width: 20%

    &--debtors
        margin-right: 2rem
        &:nth-child(1) // Date
          flex: 0 0 rem(63px)
          padding-right: 0
        &:nth-child(2) // Name
          flex: 1 0 0
          padding-right: 0
        &:nth-child(3) // MC
          flex: 0 0 11%
        &:nth-child(4) // Location
          flex: 0 0 25%
        &:last-child // Authority Status
          flex: 0 0 15%
          margin-right: 0
          padding-right: 0
          text-align: right

  &__head-wrapper
    padding: rem(10px) rem(27px)

  &__header
    border-bottom: $border
    padding: rem(41px) rem(29px) 0 rem(29px)

  &__page
    background-color: $white
    border: $border
    border-radius: $border-radius
    box-shadow: $container-shadow
    margin-bottom: rem(38px)

  &__query-input
    width: rem(182px)

  &__select
    width: rem(203px)

    .vs__selected
      &::before
        content: 'Sort by:'
        padding-right: rem(4px)

  &__table-item
    border-top: $border-b

    &:last-child
      border-bottom-left-radius: $border-radius
      border-bottom-right-radius: $border-radius

  &__top-container
    padding: rem(28px) rem(28px) rem(28px) rem(28px)

    .column
      &:nth-child(1)
        +overflow-ellipsis(2, $hyphens: true)
        padding-right: 1rem
        width: 30%
      &:nth-child(2)
        padding-right: 1rem
        width: 30%
      &:nth-child(3)
        padding-right: 1rem
        width: 20%
      &:last-child
        align-items: flex-end
        width: 20%

    &--debtors
      .column
        margin-right: 2rem
        &:nth-child(1) // Date
          flex: 0 0 rem(63px) // 63px
          padding-right: 0
        &:nth-child(2) // Name
          +overflow-ellipsis(2, $hyphens: true)
          flex: 1 0 0
          padding-right: 0
        &:nth-child(3) // MC
          flex: 0 0 11%
          padding-right: 0
        &:nth-child(4) // Location
          +overflow-ellipsis(2, $hyphens: true)
          flex: 0 0 25%
        &:last-child // Authority Status
          flex: 0 0 15%
          margin-right: 0
</style>
